import React from "react"
import Head from "../components/head"
import Layout from "../components/layout"

const SuccessPage = () => {
  return (
    <Layout>
      <Head title="Success" />

      <div id="main" className="alt">
        <section id="one">
          <div className="inner">
            <header className="major">
              <h1>Success</h1>
            </header>

            <p>Thank you for contacting me!</p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SuccessPage
